import React from "react";
import { useState } from "react";
import "@fontsource/smokum";
import {
  Container,
  AppBar,
  Typography,
  Toolbar,
  Link,
  Button,
  Box,
  List,
  Divider,
  Drawer,
  Card,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import logo from "../../Assets/wed-bbq-logo.png";
import Image from "mui-image";
const Navbar = () => {
  const [clicked, setclicked] = useState(false);

  const clickHandler = () => {
    setclicked(true);
  };

  const backHandler = () => [setclicked(false)];

  const list = () => (
    <Box
      sx={{ py: 7, width: "100%" }}
      anchor="left"
      role="presentation"
      onClick={backHandler}
      onKeyDown={{ backHandler }}
    >
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Link href="/" color="inherit" underline="none" sx={linkSX}>
          <Typography variant="h6">Home</Typography>
        </Link>
        <Divider />
        <Link href="/yehawmenu" color="inherit" underline="none" sx={linkSX}>
          <Typography variant="h6">Yehaw Menu</Typography>
        </Link>
        <Divider />
        <Link href="/bbqquote" color="inherit" underline="none" sx={linkSX}>
          <Typography variant="h6">BBQ Quote</Typography>
        </Link>
        <Divider />
        <Link href="/hollerhere" color="inherit" underline="none" sx={linkSX}>
          <Typography variant="h6"> Holler Here</Typography>
        </Link>
        <Divider />
      </List>
    </Box>
  );

  const linkSX = {
    padding: "0 50px",
    alignItems: "center",
    fontFamily: "Ultra",
    // fontSize: "1.4rem",
    "&:hover": {
      color: "grey",
    },
  };
  return (
    <AppBar
      position="sticky"
      color="primary"
      sx={{
        backgroundColor: "primary.dark",
      }}
    >
      <Container
        sx={{
          alignItems: "center",
          height: "90px",
          display: "flex",
          justifyContent: "center",
        }}
        maxWidth="xl"
      >
        <Card
          sx={{
            height: "90px",
            position: "relative",
            backgroundColor: "primary.dark",
            boxShadow: "0",
          }}
        >
          <Link href="/">
            <Image
              src={logo}
              duration="0"
              style={{
                height: "80px",
                width: "110px",
              }}
            />
          </Link>
        </Card>
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "none",
              position: "absolute",
              left: "2%",
            },
          }}
        >
          <Button onClick={clickHandler}>
            <MenuIcon sx={{ color: "white", fontSize: "35px" }} />
          </Button>

          <Drawer
            sx={{
              color: "primary.dark",
            }}
            anchor="left"
            open={clicked}
            onClose={backHandler}
          >
            {list("left")}
          </Drawer>
        </Box>
        <Toolbar
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          <Link href="/" color="inherit" underline="none" sx={linkSX}>
            <Typography variant="h6">Home</Typography>
          </Link>
          <Link href="/yehawmenu" color="inherit" underline="none" sx={linkSX}>
            <Typography variant="h6">Yehaw Menu</Typography>
          </Link>
          <Link href="/bbqquote" color="inherit" underline="none" sx={linkSX}>
            <Typography variant="h6">BBQ Quote</Typography>
          </Link>
          <Link href="/hollerhere" color="inherit" underline="none" sx={linkSX}>
            <Typography variant="h6"> Holler Here</Typography>
          </Link>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
