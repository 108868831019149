import { React } from "react";
import { Container, Box, Grid, Typography, Link } from "@mui/material";
import Image from "mui-image";
import TxMap from "../../Assets/tx-map-logo.png";
import PlaceIcon from "@mui/icons-material/Place";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

const currentDate = new Date().getFullYear();

const Footer = () => {
  const linkSX = {
    "&:hover": {
      color: "grey",
    },
  };
  return (
    <Container
      maxWidth="100%"
      height={{ sm: "auto", lg: "30vh" }}
      sx={{
        backgroundColor: "primary.dark",

        zIndex: "9999",
      }}
    >
      <Grid
        container
        height="auto"
        spacing={5}
        justifyContent="center"
        flexWrap=" wrap"
        display="flex"
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
        alignItems="center"
        sx={{ px: { sm: "20px" } }}
      >
        <Grid item xs="8" md="4" textAlign="center">
          <Typography variant="h6" sx={{ paddingBottom: "12px" }}>
            Locate Us
          </Typography>
          <Link
            color="inherit"
            href="https://www.google.com/maps/place/Austin's+Bar+B+Que/@32.9688899,-96.8306196,17z/data=!3m1!4b1!4m5!3m4!1s0x864c210806ff71f7:0x4d0755328a558899!8m2!3d32.9688899!4d-96.8306196"
            sx={linkSX}
          >
            <PlaceIcon sx={{ height: "60px", width: "70px" }} />
          </Link>
          <Typography sx={{ pt: 2 }}>weddingbbqdotcom@gmail.com</Typography>
        </Grid>
        <Grid item xs="8" md="4" textAlign="center">
          <Typography variant="h6">Proudly From Texas!</Typography>
          <Image
            duration={0}
            style={{ height: "100px", width: "100px" }}
            src={TxMap}
          />
        </Grid>

        <Grid item xs="8" md="4" textAlign="center">
          <Grid
            sx={{
              paddingBottom: "7px",
              display: "flex",
              justifyContent: "center",
              gap: 4,
            }}
          >
            <Link
              color="inherit"
              href="http://facebook.com/weddingbbqcater"
              sx={linkSX}
            >
              <FacebookIcon />
            </Link>
            <Link
              color="inherit"
              href="https://twitter.com/WeddingBBQCater"
              sx={linkSX}
            >
              <TwitterIcon />
            </Link>
          </Grid>
          <Typography>
            BBQ Commissary Location <br />
            16101 Addison Rd Addison, TX 75001 <br />
          </Typography>
          <Typography>Catering: Attn: Valerie: (682) 262-7553</Typography>
          <Link
            sx={linkSX}
            underline="none"
            color="inherit"
            href="http://www.weddingBBQ.co"
          >
            www.weddingBBQ.co
          </Link>
        </Grid>
      </Grid>
      <Box height="100px" alignItems="center" textAlign="center" padding="40px">
        <Typography variant="p" paddingTop="30px">
          Copyright {currentDate} Wedding BBQ Catering
        </Typography>
      </Box>
    </Container>
  );
};

export default Footer;
