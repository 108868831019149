import React from "react";
import { Box, List, Typography } from "@mui/material";
import { yellow } from "@mui/material/colors";
import StarRateIcon from "@mui/icons-material/StarRate";
import stapBrown from "../../../Assets/bbq-stamp-d-brown.png";
import Image from "mui-image";

const BbqQuoteContent = () => {
  return (
    <Box sx={{ p: { xs: 0, md: 4 }, backgroundColor: yellow[900] }}>
      <Box sx={{ textAlign: "center", p: { xs: 0, md: 5 } }}>
        <Typography variant="h3" sx={{ p: 4 }}>
          The Best in BBQ Catering
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: { xs: "5px", md: "100px" },
            pb: "20px",
          }}
        >
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon />
              Weddings
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon />
              Corp Events
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon /> Graduation
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon />
              Birthdays
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon />
              Retirement
            </Typography>
          </List>
          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon />
              Bah Mitzvah
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon />
              Sports Event
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon /> Party
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon />
              Grand Openings
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: { xs: "0.8rem", sm: "1.5rem" } }}
            >
              <StarRateIcon />
              Others
            </Typography>
          </List>
        </Box>
      </Box>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          p: 4,
          fontSize: { xs: "0.8rem", sm: "1.5rem", md: "2rem" },
        }}
      >
        Give us a ring at 682-262-7553 <br /> or email us :
        WeddingBBQdotcom@gmail.com
      </Typography>
      <Box sx={{ p: { xs: 0, sm: 2 } }}>
        <Image src={stapBrown} style={{ height: "300px", width: "300px" }} />
      </Box>
      <Box sx={{ p: 5, textAlign: "center" }}>
        <Typography variant="h3" sx={{ p: 5 }}>
          Submit Your Request Below
        </Typography>

        <iframe
          style={{ width: "100%" }}
          src="https://docs.google.com/forms/d/e/1FAIpQLScBYh-JUZQNQZ7B-bjrwxcvOa82__fV4clks0T7XM8ClBQQuQ/viewform?embedded=true"
          width="640"
          height="3861"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </Box>
    </Box>
  );
};

export default BbqQuoteContent;
