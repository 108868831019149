import React from "react";
import { Box, Typography, Card } from "@mui/material";
import Image from "mui-image";
import family from "../../../Assets/austin-family.png";
import { brown } from "@mui/material/colors";

const HollerHereContent = () => {
  return (
    <Box
      sx={{
        px: { xs: 3, md: 5 },
        py: 8,
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "30px",
        backgroundColor: brown[900],
      }}
    >
      <Typography variant="h3">The Austin Family</Typography>
      <Card sx={{ border: "5px solid" }}>
        <Image style={{ height: "300px", width: "300px" }} src={family} />
      </Card>
      <Typography sx={{ width: { xs: "90%", md: "60%" } }}>
        Robert Austin has been in the restaurant industry for over 40yrs from
        fine dining to pit BBQ. His experience brings him to be a great pit
        master, standing over the BBQ pit for over 30yrs. He is an Award Winning
        BBQ Pit Master. Valerie Austin has been in fine dining to BBQ catering
        for decades for North Texas: Dallas, Richardson, Garland, Plano, Frisco,
        Allen, McKinney, Fort Worth, HEB, Arlington and all surroundings in the
        DFW area. She's has been bringing fine Authentic Texas BBQ made by
        Robert, to so many corporate events, wedding, holiday parties, birthdays
        and more. Both Robert and Valerie are passionate about their craft and
        enjoy seeing happy patrons enjoy their Authentic Texas BBQ.
      </Typography>
      <Typography sx={{ width: { xs: "90%", md: "60%" } }}>
        Come see them soon at their location near Addison Airport off Addison Rd
        and Keller Springs Rd. You can order online, call and Holler Valerie to
        set up your next catering event. They love feeding hungry folks.
      </Typography>
      <Typography sx={{ width: { xs: "90%", md: "60%" } }} variant="h6">
        Book your next event here soon, it is a large restaurant with great
        parking.
      </Typography>
      <Typography
        sx={{
          width: { xs: "100%", md: "60%" },
          fontSize: { xs: "13px", sm: "1.2rem" },
        }}
        variant="h6"
      >
        Give us a ring at 682-262-7553 <br /> or email us :
        WeddingBBQdotcom@gmail.com
      </Typography>
    </Box>
  );
};

export default HollerHereContent;
